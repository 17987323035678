<template>
  <div style="min-width: 1360px;">
    <new-cloude-header></new-cloude-header>
    <headerBanner :content="bannerContent" title="数字办公" @btnClick="bannerClick" imgStyle="height:0%;height:0"
    :price="bannerPrice" contentStyle="width:80%" :bodyStyle="bodyStyle" />
    <div class="cell-card">
      <div class="content">
        <div class="content-head">
          <div class="content-head-tips">组织的管理难题</div>
          <div class="content-head-title">数字办公为您解决</div>
        </div>
        <div class="card-flex">
          <div class="card-flex-box" v-for="(item,i) in dataList" :key="i">
            <div class="card-flex-box-title">{{ item.title }}<span>{{ item.blueTitle }}</span></div>
            <div class="card-flex-box-content">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="banner-box">
      <div class="content">
        <div class="content-head">
          <div class="content-head-tips">人事管理多端适配</div>
          <div class="content-head-title">管事理人，与业务融为一体</div>
          <div class="dataList">
            <div class="dataList-cell" v-for="(item,i) in dataList2" :key="i">
              <img :src="item.icon" style="width: 20px;">
              <span>{{ item.title }}</span>
            </div>
          </div>
        </div>
        <div style="margin-left: 185px;padding-top: 60px;">
          <el-image style="width: 410px; height: 410px" :src="`${require('@/assets/img/home/rencaiguanli1.png')}`"
                    fit="contain"/>
        </div>
      </div>
    </div>
    <div class="switchBox">
      <div class="content">
        <div class="content-head">
          <div class="content-head-tips">构建多行业应用</div>
          <div class="content-head-title">OA让百行千业更高效</div>
        </div>
        <div class="switch-container">
          <div class="swrich-card" v-for="(row,i) in OAdata" :key="i" @click="currentIndex = i">
            <img style="width: 100%;height: 100%;object-fit: cover;"
                 :src="`${require('@/assets/img/home/OA_swiper_'+ parseInt(i+1) + '.png')}`">
            <div class="swrich-card-div" :class="{isActive:i == currentIndex}">{{ row.title }}</div>
          </div>
        </div>
        <div style="display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap;margin-top: 30px;">
          <div class="switch-message-card" v-for="(item,i) in showDataList" :key="i">
            <div class="switch-message-card-title">{{ item.title }}</div>
            <ul class="card-ul">
              <li v-for="(text,index) in item.textAttar" :key="index"><span style="color: #FE273B;">◆</span>{{ text }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-btn">
      <el-button type="primary" @click="bannerClick">申请试用</el-button>
    </div>
    <applyTrial ref="dialog" :servicePlatform="'数字办公'"/>
    <new-cloude-footer/>
  </div>
</template>
<script>
import bannerList from "@/components/assembly/bannerList.vue";
import newHomeHeader from "@/components/assembly/newHomeHeander";
import newCloudeHeader from "@/views/home/newCloudeHeader.vue";
import applyTrial from "@/components/assembly/applyTrial";
import newCloudeFooter from "@/views/home/newCloudeFooter.vue";
import OAdata from '@/static/OAdata.json'
import headerBanner from '@/components/assembly/headerBanner'

export default {
  components: {bannerList, newHomeHeader, newCloudeHeader, applyTrial, newCloudeFooter, headerBanner},
  data() {
    return {
      bannerPrice:'市场价格：160000元',
      bodyStyle: `background-image:url(${require('@/assets/img/home/OA.png')});background-size: 840px 100%;background-repeat: no-repeat;background-position: right;`,
      bannerContent: '促进企业协同办公，提高工作效率和管理水平。规范化管理企业的各种工作流程，实现在线审批、签字、盖章等操作，节省时间和成本。支持移动办公，使员工和管理者能够跨越时空障碍，随时随地进行协作和沟通。记录和跟踪企业的各类业务数据，实现对人员、资产、客户等的全面管理和控制。',
      dataList: [
        {
          title: '业务办理',
          blueTitle: '效率低',
          content: '办事跨部门咨询、沟通、找人签字，程序多、周期长，效率难保障。'
        },
        {
          title: '内部沟通',
          blueTitle: '协作难',
          content: '各部门沟通费时费力，具体事项不知道负责人是谁，沟通协作难。'
        },
        {
          title: '规章制度',
          blueTitle: '落实难',
          content: '现有规章制度无法督促落实，不能确保每项业务经过合规审批授权。'
        },
        {
          title: '签字盖章',
          blueTitle: '效率低',
          content: '签字盖章停留在面签、手动操作阶 段，负责人不在，业务就推进不了。'
        },
        {
          title: '责任追踪',
          blueTitle: '查询难',
          content: '办事过程不透明、ID账号易盗用， 申请-审批缺乏可信记录，追责难。'
        },
        {
          title: '分公司',
          blueTitle: '管控难',
          content: '分子公司分布在各地，业务运营、制度落实情况看不见，无法管控。'
        },
        {
          title: '内部知识',
          blueTitle: '流失快',
          content: '各类知识存在系统和个人电脑里，收集不及时-流失快-用的时候找不到。'
        },
        {
          title: '印章管理',
          blueTitle: '风险高',
          content: '实体印章管理中存在盗用-冒用-乱用-丢失等风险，使用效率低、成本高'
        }
      ],
      dataList2: [
        {
          icon: require('@/assets/img/home/personnel_icon_1.png'),
          title: 'CEO/高管洞察组织全貌与人才全貌，提升管理效率'
        },
        {
          icon: require('@/assets/img/home/personnel_icon_2.png'),
          title: '应聘、入职融入、培训、考勤、薪酬福利、目标绩效、盘点继任、离职，员工全生命周期数字化管理无断点'
        },
        {
          icon: require('@/assets/img/home/personnel_icon_3.png'),
          title: '多种角色专属工作台，业务人力数据集中一体，抽身繁琐事务，推动业务发展'
        },
        {
          icon: require('@/assets/img/home/personnel_icon_4.png'),
          title: '流程体系助力企业数字化快速落地，流程监控看板指标化诊断，提效人力流程'
        }
      ],
      OAdata: OAdata,
      currentIndex: 0,
    }
  },
  computed: {
    showDataList() {
      return this.OAdata[this.currentIndex].list
    }
  },
  methods: {
    bannerClick() {
      this.$refs.dialog.show = true;
    }
  }
}
</script>
<style scoped>
.bottom-btn >>> .el-button--primary {
  color: #FFF;
  background-color: #1935DE;
  border-color: #1935DE;
  padding: 15px 60px;
}
</style>
<style lang="scss" scoped>
.cell-card {
  background-color: #fff;
  padding: 60px 0;

  .content {
    width: 1200px;
    margin: auto;

    &-head {
      &-tips {
        font-size: 18px;
        line-height: 34px;
      }

      &-title {
        font-size: 24px;
      }
    }

    .card-flex {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 20px;

      &-box {
        width: 290px;
        height: 120px;
        background-color: #E8EBFF;
        padding: 20px 20px 0;
        box-sizing: border-box;
        margin-bottom: 10px;

        &-title {
          font-size: 18px;
          font-weight: bold;
          color: #333;

          > span {
            color: #1935DE;
          }
        }

        &-content {
          font-size: 14px;
          line-height: 1.8;
          margin-top: 10px;
        }
      }
    }
  }
}

.banner-box {
  padding: 60px 0;
  background-color: #F7F8FE;

  .content {
    width: 1200px;
    margin: auto;
    display: flex;
    align-items: flex-start;

    &-head {
      width: 460px;

      &-tips {
        font-size: 18px;
        line-height: 34px;
      }

      &-title {
        font-size: 24px;
      }
    }

    .dataList {
      display: flex;
      flex-direction: column;
      margin-top: 30px;

      &-cell {
        display: flex;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px solid #ECEDF3;

        > span {
          margin-left: 14px;
          font-size: 16px;
          color: #333;
          flex: 1;
        }
      }
    }
  }
}

.switchBox {
  background-color: #fff;
  padding: 60px 0;

  .content {
    width: 1200px;
    margin: auto;

    &-head {
      &-tips {
        font-size: 18px;
      }

      &-title {
        font-size: 24px;
      }
    }
  }
}

.switch-container {
  display: flex;
  align-items: center;
  margin-top: 40px;

  .swrich-card {
    width: 160px;
    height: 60px;
    margin-right: 28px;
    cursor: pointer;
    position: relative;

    &-div {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      font-size: 18px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }

    .isActive {
      background-color: #1935DE;

      &::after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-width: 20px 20px 0 20px;
        border-style: solid;
        border-color: #1935DE transparent transparent transparent;
        position: absolute;
        left: 60px;
        bottom: -14px;
      }
    }
  }
}

.switch-message-card {
  width: 390px;
  height: 195px;
  background: linear-gradient(-30deg, #F4F5FC, #EEF0FF);
  margin-bottom: 14px;
  box-sizing: border-box;
  padding: 30px 30px 0;

  &-title {
    font-size: 16px;
    font-weight: bold;
    padding-left: 20px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 4px;
      height: 18px;
      background-color: #1935DE;
      left: 0;
      top: 2px;
    }
  }

  .card-ul {
    list-style-type: none;
    padding-left: 0;

    li {
      font-size: 14px;
      margin: 14px 0;
      display: flex;
      align-items: center;

      > span {
        font-size: 18px;
        margin-right: 6px;
      }
    }
  }
}

.bottom-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 50px;
}
</style>
